var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Country..",
                          options: _vm.authCountries,
                          reduce: (c) => c.value,
                          searchable: false,
                        },
                        on: { input: _vm.getAllUsers },
                        model: {
                          value: _vm.filters.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "country", $$v)
                          },
                          expression: "filters.country",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "User Language..",
                          options: _vm.allLanguages,
                          reduce: (c) => c.value,
                          searchable: false,
                          multiple: "",
                        },
                        on: { input: _vm.getAllUsers },
                        model: {
                          value: _vm.filters.users.selectedLanguages,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters.users,
                              "selectedLanguages",
                              $$v
                            )
                          },
                          expression: "filters.users.selectedLanguages",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Type..",
                          options: _vm.allTypes,
                          searchable: false,
                        },
                        on: { input: _vm.getAllUsers },
                        model: {
                          value: _vm.filters.users.selectedType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters.users, "selectedType", $$v)
                          },
                          expression: "filters.users.selectedType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-12 col-filter mb-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: {
                          type: "search",
                          placeholder: "Search for something else ...",
                        },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          hover: "",
          striped: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No users found!",
          },
          "clickable-rows": "",
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "row-clicked": _vm.rowClicked,
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "image_icon",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.imageClicked(item)
                          },
                        },
                      },
                      [
                        _c("CImg", {
                          staticClass: "c-avatar-img",
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: item.image_icon || item.gravatar,
                            placeholderColor: "lightgray",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "country",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.country
                      ? [
                          _c("CIcon", {
                            staticClass: "mr-1",
                            attrs: {
                              content: _vm.$options.flagSet[item.country.flag],
                              size: "lg",
                            },
                          }),
                          _vm._v(" " + _vm._s(item.country.label) + " "),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "lang",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.lang == "en"
                      ? _c("CIcon", {
                          staticClass: "flag",
                          attrs: {
                            height: 14,
                            content: _vm.$options.flagSet["cifGb"],
                          },
                        })
                      : _c("CIcon", {
                          staticClass: "flag",
                          attrs: {
                            height: 14,
                            content:
                              _vm.$options.flagSet[
                                "cif" +
                                  item.lang.charAt(0).toUpperCase() +
                                  item.lang.slice(1)
                              ],
                          },
                        }),
                    _vm._v(" " + _vm._s(item.lang.toUpperCase()) + " "),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "orders",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "div",
                    [
                      _vm._v(" Real Orders: "),
                      item.orders_count
                        ? _c(
                            "CLink",
                            {
                              attrs: {
                                to: {
                                  name: "UserOrderList",
                                  params: { user_id: item.id },
                                },
                              },
                            },
                            [_c("strong", [_vm._v(_vm._s(item.orders_count))])]
                          )
                        : _c("span", [_vm._v("0")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm._v(" Latest: "),
                      item.latest_order
                        ? _c(
                            "CLink",
                            {
                              attrs: {
                                to: {
                                  name: "Order Details",
                                  params: { id: item.latest_order.id },
                                },
                              },
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(item.latest_order.created_at)),
                              ]),
                            ]
                          )
                        : _c("span", [_vm._v("-")]),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "point",
            fn: function ({ item }) {
              return [
                _c("td", { staticClass: "text-center" }, [
                  _c("strong", { staticClass: "text-success" }, [
                    _vm._v(_vm._s(item.point?.points ?? 0)),
                  ]),
                  _vm._v(" / "),
                  _c("strong", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(item.total_points_used)),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "reviews_count",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "CLink",
                      {
                        attrs: {
                          to: {
                            name: "UserReviews",
                            params: { user_id: item.id },
                          },
                        },
                      },
                      [_c("strong", [_vm._v(_vm._s(item.reviews_count))])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "contact",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-at" },
                      }),
                      _vm._v(_vm._s(item.email)),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-phone" },
                      }),
                      _vm._v(_vm._s(item.mobile)),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-location-pin" },
                      }),
                      _vm._v(_vm._s(_vm._f("ucfirst")(item.city))),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "usertype",
            fn: function (data) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CBadge",
                      { attrs: { color: _vm.getBadge(data.item.usertype) } },
                      [_vm._v(" " + _vm._s(data.item.usertype) + " ")]
                    ),
                    data.item.usertype === "Admin"
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  content: "2FA Mobile Number",
                                  placement: "bottom",
                                },
                                expression:
                                  "{content: '2FA Mobile Number', placement: 'bottom'}",
                              },
                            ],
                          },
                          [
                            _c("CIcon", {
                              staticClass: "mr-1",
                              attrs: { name: "cil-mobile" },
                            }),
                            data.item.tfamobile
                              ? _c("strong", [
                                  _vm._v(_vm._s(data.item.tfamobile)),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "type",
            fn: function (data) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CBadge",
                      { attrs: { color: _vm.getBadge(data.item.type) } },
                      [_vm._v(" " + _vm._s(data.item.type) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          size: "sm",
                          color: "info",
                          variant: "ghost",
                          shape: "pill",
                          to: { name: "Edit User", params: { id: item.id } },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }